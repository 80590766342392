import { mobile, styled } from '@obvio/app'
import {
  Form,
  Grid,
  ObvioFooter,
  Section,
  Stack,
  Submit,
  TextInput,
} from '@obvio/ui'

export const FullWidthInput = styled(TextInput)`
  width: 100%;
  input::placeholder {
    white-space: pre-line;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const SubmitBtn = styled(Submit)`
  font-family: ${(theme) => theme.font};
  height: auto;
  display: flex;
  align-items: center;
  @media ${mobile} {
    display: none;
  }
`

export const SubmitBtnMobile = styled(SubmitBtn)`
  display: none;
  @media ${mobile} {
    margin-top: ${(theme) => theme.spacing.large} !important;

    display: flex;
  }
`

export const NewsletterForm = styled(Form)`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: ${(theme) => theme.spacing.extraLarge};
`

export const FullWidthStack = styled(Stack)`
  width: 100%;

  @media ${mobile} {
    align-items: flex-start;
  }
`
export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${mobile} {
    flex-direction: column;
    align-items: flex-start;
    > h3 {
      margin-bottom: ${(theme) => theme.spacing.large};
    }
  }
`

export const BottomBar = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  > div:first-of-type {
    justify-content: flex-start;
  }
  > div:last-of-type {
    justify-content: flex-end;
  }
  @media ${mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div {
      margin-bottom: ${(theme) => theme.spacing.medium};
    }
  }
`

export const BlackFooter = styled.footer`
  background: #000000;
  * {
    color: #ffffff !important;
  }
`

export const Wrap = styled(Section)`
  background: #000000;
  padding: ${(theme) => theme.spacing.small} ${(theme) => theme.spacing.medium};
  height: 39rem;
  display: flex;
  flex-direction: column;
  @media ${mobile} {
    height: 50rem;
  }
`

export const ObvioFooterBlack = styled(ObvioFooter)`
  background: #000000;
  color: #ffffff;
`
