import { Link, styled, Text, tinyMobile } from '@obvio/app'
import { motion } from 'framer-motion'

import { Arrow } from './Arrow'

import type { Href } from '@obvio/app'
import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const StyledLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @media ${tinyMobile} {
    padding-top: ${(theme) => theme.spacing.small};
  }
  > div {
    display: flex;
    align-items: center;
    > div {
      margin-left: ${(theme) => theme.spacing.medium};
    }
  }
`

const MotionLink = motion(StyledLinkWrapper)

type ReadMoreLinkProps = AllowUndefinedForOptional<{
  children?: React.ReactNode
  href?: string | Href
  className?: string
  length?: string
  color?: string
  onClick?(): void
}>

export function ReadMoreLink({
  children,
  href,
  className,
  color,
  length = '7rem',
  onClick,
}: ReadMoreLinkProps): ReactElement | null {
  return (
    <MotionLink
      onClick={onClick}
      className={className}
      href={href}
      whileHover="hover"
    >
      <Text tag="span">{children}</Text>
      <motion.div variants={{ hover: { x: [0, 10, 0] } }}>
        <Arrow color={color} direction="right" length={length} />
      </motion.div>
    </MotionLink>
  )
}
