import { AssetsProvider, Image, styled, Text } from '@obvio/app'
import { useCart } from '@obvio/template'
import { Button, Stack } from '@obvio/ui'

import type { CartProduct } from '@obvio/template'
import type { ReactElement } from 'react'

const Wrap = styled(Stack)`
  border-bottom: 1px solid ${(theme) => theme.stroke};
  padding: ${(theme) => theme.spacing.big} 0;
  &:first-of-type {
    border-top: 1px solid ${(theme) => theme.stroke};
    margin-top: ${(theme) => theme.spacing.medium};
  }
`
const ImgWrap = styled.div`
  width: 30%;
`
const Merchant = styled(Text)`
  text-transform: uppercase;
`
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Content = styled(Stack)`
  flex: 1;
`
const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

type CustomProductViewProps = {
  product: CartProduct
}
export function CustomProductView({
  product,
}: CustomProductViewProps): ReactElement {
  const { removeProduct } = useCart()
  return (
    <Wrap spacing="medium" fullWidth>
      <ImgWrap>
        <AssetsProvider path={`/product/${product.id}`}>
          <Image aspectRatio img={product.image} />
        </AssetsProvider>
      </ImgWrap>
      <ContentWrap>
        <Content kind="vertical" spacing="extraSmall">
          <Merchant tag="span" as="p">
            {product.merchant?.name}
          </Merchant>
          <Text tag="span" as="sub-p">
            {product.title}
          </Text>
        </Content>
        <ButtonWrap>
          <Button
            kind="transparent"
            onClick={() => void removeProduct(product.cartVariantId)}
          >
            Delete
          </Button>
        </ButtonWrap>
      </ContentWrap>
    </Wrap>
  )
}
