import { css, useMutation, useTranslation, styled } from '@obvio/app'
import { CheckboxInput, Stack } from '@obvio/ui'

import {
  FullWidthInput,
  FullWidthStack,
  NewsletterForm,
  SubmitBtn,
  SubmitBtnMobile,
} from './components'
import { Arrow } from '../Arrow'

import type { ReactElement } from 'react'

const containerCss = css`
  width: 100%;
`

const CheckboxWrap = styled(Stack)`
  > div {
    width: auto;
  }
`

export function FooterForm(): ReactElement {
  const [register] = useMutation('registerNewsletter')

  const { t } = useTranslation()

  const handleSubmit = async (data: {
    email: string
    news: boolean
    events: boolean
    collageArt: boolean
    digital: boolean
  }) => {
    await register(data)
  }
  return (
    <NewsletterForm onSubmit={handleSubmit}>
      <FullWidthStack kind="vertical">
        <FullWidthInput
          containerCss={containerCss}
          name="email"
          type="email"
          optional
          placeholder={t('footer.input')}
          suffix={
            <SubmitBtn kind="transparent">
              <>
                {t('label.submit')} <Arrow length="4rem" direction="right" />
              </>
            </SubmitBtn>
          }
        />
        <CheckboxWrap spacing="extraSmall" mobileKind="vertical">
          <CheckboxInput name="news" label={t('newsletter.artNews')} />
          <CheckboxInput name="events" label={t('newsletter.events')} />
          <CheckboxInput name="collageArt" label={t('newsletter.collageArt')} />
          <CheckboxInput name="digital" label={t('newsletter.digital')} />
        </CheckboxWrap>
        <SubmitBtnMobile kind="transparent">
          <>
            {t('label.submit')}
            <Arrow length="4rem" direction="right" />
          </>
        </SubmitBtnMobile>
      </FullWidthStack>
    </NewsletterForm>
  )
}
