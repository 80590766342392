import { Context, css, Link, Text, useTranslation } from '@obvio/app'
import { GridItem, Stack } from '@obvio/ui'

import { useCursorContext } from './Cursor'
import {
  BottomBar,
  BlackFooter,
  ObvioFooterBlack,
  Wrap,
  TopBar,
} from './Footer/components'
import { FooterForm } from './Footer/FooterForm'
import { SOCIALS } from '@/utils/CONSTS'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const outerCss = css`
  background: #000000;
`

type FooterProps = AllowUndefinedForOptional<{
  cursorDefaultWhite?: boolean
}>

export function Footer({ cursorDefaultWhite }: FooterProps): ReactElement {
  const { t } = useTranslation()

  const { setKind } = useCursorContext()
  return (
    <Context name="footer">
      <BlackFooter
        onMouseEnter={() => setKind('white')}
        onMouseLeave={() => setKind(cursorDefaultWhite ? 'white' : 'black')}
      >
        <Wrap outerCss={outerCss}>
          <TopBar>
            <Text tag="h3" as="sub-h1">
              {t('footer.title')}
            </Text>
            <Stack divider={() => <span>-</span>} spacing="0.4rem">
              <Link href={SOCIALS.instagram}>Instagram</Link>
              <Link href={SOCIALS.facebook}>Facebook</Link>
              <Link href={SOCIALS.youtube}>YouTube</Link>
            </Stack>
          </TopBar>
          <FooterForm />
          <BottomBar templateColumns="repeat(2,minmax(0,1fr))">
            <GridItem>
              <Text tag="span">Fijewski Gallery 2022 All Rights Reserved.</Text>
            </GridItem>
            <GridItem>
              <Stack>
                <Link href="https://www.google.com/maps/dir/52.2634029,21.0517094/elektrownia+powiśle/@52.2515203,21.0177491,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x471ecc5980628c37:0x5b3b055a9d8fb8d9!2m2!1d21.0279312!2d52.2407273?entry=ttu">
                  Elektrownia Powiśle
                </Link>
                <span>|</span>
                <Link href="https://www.google.com/maps/dir//villa+poranek/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x47192fe7bc121c59:0x4897d449bf238e70?sa=X&ved=2ahUKEwiw-LCs8LX_AhXDlosKHY_uChIQ9Rd6BAhWEAU">
                  Villa Poranek
                </Link>
              </Stack>
            </GridItem>
          </BottomBar>
        </Wrap>
        <ObvioFooterBlack />
      </BlackFooter>
    </Context>
  )
}
