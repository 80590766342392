import { keyframes, styled, useInView } from '@obvio/app'
import { useEffect, useState } from 'react'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type FadeInProps = AllowUndefinedForOptional<{
  children: ReactElement | ReactElement[]
  delay?: number
  className?: string
  y?: number
}>

const bounce = (num: number) => keyframes`
  from {
    transform: translateY(${num}px);
opacity: 0;
  }

  to {
 	 transform: translateY(0px);
opacity: 1;
	}
}
`

const Wrap = styled.div<{ $inView: boolean; $y: number; $delay: number }>`
  width: 100%;
  max-width: 100%;
  opacity: 0;
  transform: translateY(${(_, { $y }) => $y}px);
  animation-play-state: ${(_, { $inView }) => ($inView ? 'running' : 'paused')};
  animation-name: ${(_, { $y }) => bounce($y)};
  animation-duration: 0.65s;
  animation-delay: ${(_, { $delay }) => $delay}s;
  animation-fill-mode: forwards;
`

export function FadeIn({
  children,
  className,
  delay = 0.25,
  y = 50,
}: FadeInProps): ReactElement {
  const [animate, setAnimate] = useState(false)
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      setAnimate(true)
    }
  }, [delay, inView])

  return (
    <Wrap
      ref={ref}
      className={className}
      $inView={animate}
      $y={y}
      $delay={delay}
    >
      {children}
    </Wrap>
  )
}
