import {
  css,
  LANGUAGES,
  notDesktop,
  styled,
  switchLang,
  useTranslation,
} from '@obvio/app'
import { SvgFijewskiText, SvgLogoSmall, SvgMenu } from '@obvio/svg'
import { StoreNav2, StoreNav2ActionsView, useUi } from '@obvio/template'
import { Button, Stack } from '@obvio/ui'

import { useCursorContext } from './Cursor'
import { FadeIn } from './FadeIn'

import type { AllowUndefinedForOptional, Dict } from '@obvio/utils'
import type { ReactElement } from 'react'

const MaxWidthNav = styled(StoreNav2)<{
  $transparent: boolean
  $navTransparent: boolean
  $dark: boolean
}>`
  > div {
    max-width: calc(100% - 7.5rem);
    margin: 0 auto;
    > div {
      > div {
        width: 33%;
      }
      > a {
        width: 33%;
      }
      > div:last-of-type {
        justify-content: flex-end;
      }
    }
  }
  @media ${notDesktop} {
    > div {
      max-width: calc(100% - 3rem);
    }
  }
  z-index: 4;
  margin: 0 auto;
  ${(_, { $transparent }) =>
    $transparent &&
    css`
      z-index: 10;
      left: 0;
      right: 0;
      background: transparent;
      * {
        color: white !important;
      }
      svg {
        color: white !important;
      }
      position: absolute;
    `}

  ${(_, { $navTransparent }) =>
    $navTransparent &&
    css`
      background: transparent;
    `}

  ${(_, { $dark }) =>
    $dark &&
    css`
      background: #0f0f0f;
      * {
        color: white;
      }
    `}
`

const LangButton = styled(Button)<{ $white: boolean }>`
  font-size: 1rem;
  min-width: unset;
  width: unset;
  height: unset;
  padding: 0.5rem;
  font-weight: 400;
  color: ${(_, { $white }) => ($white ? 'white' : 'black')};

  &:not(:first-of-type) {
    margin-left: 1.5rem;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 1.5rem;
      position: absolute;
      left: -0.75rem;
      background-color: ${(_, { $white }) => ($white ? 'white' : 'black')};
      pointer-events: none;
    }
  }

  &:last-of-type {
    margin-right: 0.75rem;
  }

  &:disabled {
    font-weight: bold;
  }
`

const NavMenuWrap = styled(Stack)`
  > svg {
    display: none;
  }
  @media ${notDesktop} {
    > svg {
      display: block;
    }
  }
  align-items: center;
`

const langsMap: Dict = {
  en: 'ENG',
}

function LangSwitcher({ white }: { white: boolean }): ReactElement {
  const { locale: currentLang } = useTranslation()
  return (
    <>
      {LANGUAGES.map((lang) => (
        <LangButton
          $white={white}
          key={lang}
          kind="transparent"
          onClick={() => {
            switchLang(lang)
          }}
          disabled={lang === currentLang}
        >
          {langsMap[lang] ?? lang.toUpperCase()}
        </LangButton>
      ))}
    </>
  )
}

// Custom Nav Menu since we want to toggle "TOGGLE_MENU_PANEL" instead of "TOGGLE_ECOM_MENU_PANEL"
function NavMenu(): ReactElement {
  const [, dispatch] = useUi()
  return (
    <NavMenuWrap>
      <Button
        kind={['icon', 'transparent']}
        onClick={() => dispatch('TOGGLE_MENU_PANEL')}
      >
        <SvgMenu />
      </Button>
      <SvgLogoSmall />
    </NavMenuWrap>
  )
}

type NavProps = AllowUndefinedForOptional<{
  transparent?: boolean
  dark?: boolean
}>

export function Nav({ transparent, dark }: NavProps): ReactElement {
  const { navTransparent } = useCursorContext()
  return (
    <MaxWidthNav
      $transparent={Boolean(transparent)}
      $dark={Boolean(dark)}
      $navTransparent={navTransparent}
      logo={SvgFijewskiText}
      mobileLogo={() => null}
      menu={NavMenu}
      wrapper={FadeIn}
      actions={() => (
        <Stack align="center">
          <LangSwitcher white={Boolean(transparent || dark)} />
          <StoreNav2ActionsView />
        </Stack>
      )}
    />
  )
}
