import {
  Context,
  css,
  Global,
  styled,
  Text,
  useContextPath,
  useIsClient,
  useQuery,
  useRouter,
} from '@obvio/app'
import { SvgLogoSmall } from '@obvio/svg'
import { Cart, MenuPanel, useUi } from '@obvio/template'
import { Stack } from '@obvio/ui'
// @ts-expect-error
import { WhatsAppWidget } from 'react-whatsapp-widget'

import { useCursorContext } from './Cursor'
import { FadeIn } from './FadeIn'
import { Footer } from './Footer'
import { Nav } from './Nav'
import { ReadMoreLink } from './ReadMoreLink'
import { CustomProductView } from './Store/CustomProductView'

import type { ThemeContext } from '@obvio/app'
import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

type LayoutProps = AllowUndefinedForOptional<{
  context?: ThemeContext
  transparent?: boolean
  dark?: boolean
  children: ReactElement | ReactElement[]
}>

const globalStyle = css`
  body {
    background-color: ${(theme) => theme.background};
  }
  ${(theme) => theme.global}
`

const Interactive = styled.div`
  height: 100%;
  > div {
    height: 100%;
  }
`
const FullFadeIn = styled(FadeIn)`
  height: 100%;
`

const AdditionalWrap = styled.div`
  flex: 1;
  align-items: flex-end;
  display: flex;
  color: white;
`

const WhatsAppWrap = styled.div`
  > div {
    z-index: 100;
    color: ${(theme) => theme.colors.primary.dark};
    > div {
      background-color: ${(theme) => theme.colors.primary};
      div > svg {
        color: ${(theme) => theme.colors.primary.dark};
      }
      span > svg {
        color: white;
      }
      button {
        background-color: white;
        color: ${(theme) => theme.colors.primary};
      }
    }
  }
`

export function Layout({
  children,
  context,
  transparent,
  dark,
}: LayoutProps): ReactElement {
  const isClient = useIsClient()
  const { push } = useRouter()
  const eventPath = useContextPath('event')
  const { setValue } = useCursorContext()
  const [, dispatch] = useUi('menuPanelOpen')

  const { data: event, loading } = useQuery('getEventNearest', {
    select: MenuPanel.Event.SELECT,
  })

  const isStore = context === 'store'

  return (
    <Context name={context ?? 'default'} render="div">
      {isClient ? (
        <WhatsAppWrap>
          <WhatsAppWidget
            CompanyIcon={SvgLogoSmall}
            companyName="Fijewski Gallery"
            phoneNumber="48516450060"
          />
        </WhatsAppWrap>
      ) : null}
      <Nav transparent={transparent} dark={dark} />
      {children}
      <Footer cursorDefaultWhite={dark} />
      <Global styles={globalStyle} />
      {isStore && (
        <Context name="cart">
          <Cart noQuantity productView={CustomProductView} />
        </Context>
      )}
      <Context name="menuPanel">
        <MenuPanel
          panels={[
            <FullFadeIn key="links">
              <MenuPanel.Links
                links={[
                  {
                    href: '/',
                    label: 'HOME',
                  },
                  {
                    href: '/about',
                    label: 'ABOUT',
                  },
                  {
                    href: '/artists',
                    label: 'ARTISTS',
                  },
                  {
                    href: '/store',
                    label: 'SHOP ART',
                  },
                  {
                    href: '/join',
                    label: 'JOIN US',
                  },
                  {
                    href: '/sell',
                    label: 'SELL ART',
                  },
                  {
                    href: '/blog',
                    label: 'BLOG',
                  },
                  {
                    href: '/contact',
                    label: 'CONTACT',
                  },
                ]}
              />
            </FullFadeIn>,
            <FullFadeIn key="event">
              <Interactive
                onMouseEnter={() => setValue('EXPLORE')}
                onMouseLeave={() => setValue(null)}
                onClick={() => {
                  if (!event?.slug) {
                    return
                  }
                  void push(`${eventPath}/${event.slug}`)
                  setValue(null)
                  dispatch('CLOSE_ALL_PANELS')
                }}
              >
                <MenuPanel.Event {...{ event, loading }} />
              </Interactive>
            </FullFadeIn>,
            <FullFadeIn key="socials">
              <MenuPanel.Socials
                socials={{
                  instagram: 'https://www.instagram.com/fijewski.gallery',
                  facebook: 'https://www.facebook.com/FijewskiGallery',
                  linkedIn:
                    'https://www.linkedin.com/company/fijewski-gallery/',
                }}
                additional={
                  <AdditionalWrap>
                    <Stack kind="vertical">
                      <Text tag="span">BE A PART OF OUR FUTURE EVENT</Text>
                      <ReadMoreLink href="/creative">
                        CHECK DETAILS
                      </ReadMoreLink>
                    </Stack>
                  </AdditionalWrap>
                }
              />
            </FullFadeIn>,
          ]}
        />
      </Context>
    </Context>
  )
}
